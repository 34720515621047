import React, { Component } from "react";
import "../css/Admin_css/admin-customize.css";
import "../css/Admin_css/vp_style.css";
import MyLoader from "../Spinner/MyLoader";
import Login from "../login";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import SessionManager from "../Utils/SessionManager";
import axios from "axios";
import myToast from "../Utils/MyToast";
import ModuleList from "./ModuleList";
import StaffPrivilege from "./StaffPrivilege";
import Security from "./Security";
import Settlement from "./Settlement";
import ClassList from "../cms/ClassList";
import NoticeBoard from "../cms/NoticeBoard";
import FeeDashBoard from "../cms/FeeDashBoard";
import AdmissionDashboard from "../cms/AdmissionDashboard";
import AttendanceDashboard from "../cms/AttendanceDashboard";
import HelpDesk from "../cms/HelpDesk";
import Holiday from "../cms/Holiday";
import LeaveManagement from "../cms/LeaveManagement";
import Comment from "../cms/Comment";
import SchoolProfile from "../cms/SchoolProfile";
import AdminNotification from "./AdminNotification";
import LeaveManagementAdmin from "./LeaveManagementAdmin";
import ManageRecords from "./ManageRecords";
import RevenueManagement from "./RevenueManagement";
import Transport from "../cms/Transport";
import Academic from "../cms/Academic";
import Notification from "../cms/Notification";
import LearningManagement from "../cms/LearningManagement";
import HomeWork from "../cms/HomeWork";
import StaffList from "../cms/StaffList";
import Settings from "../cms/Settings";
import MyProfile from "../cms/MyProfile";
import NavMenuAdmin from "./NavMenuAdmin";
import Error from "./Error";
import StudentList from "../cms/StudentList";

import PaymentHistory from "../cms/PaymentHistory";
import PaymentDetails from "../cms/PaymentDetails";

import StudentDetails from "../cms/StudentDetails";
import FeeCard from "../cms/FeeCard";
import AdvancedPayment from "../cms/AdvancedPayment";
import PaymentStatus from "../cms/PaymentStatus";
import FeeReceipt from "../cms/FeeReceipt";
import FeeCollection from "../cms/FeeCollection";
import CurrentDue from "../cms/CurrentDue";
import StudentRegistration from "../cms/StudentRegistration";
import UpdateSchoolDetails from "../cms/UpdateSchoolDetails";
import ChangePassword from "../cms/ChangePassword";
import AddHoliday from "../cms/AddHoliday";
import BatchShifting from "../cms/BatchShifting";
import EditStaff from "../cms/EditStaff";
import StaffRegistration from "../cms/StaffRegistration";
import TotalRecieved from "../cms/TotalRecieved";
import UpdateStudentDetails from "../cms/UpdateStudentDetails";
import ManageNotice from "../cms/ManageNotice";
import SendNotification from "../cms/SendNotification";
import AddNotice from "../cms/AddNotice";
import Accessories from "../cms/Accessories";
import TotalDue from "../cms/TotalDue";
import TotalFee from "../cms/TotalFee";
import Acknowledgement from "../cms/Acknowledgement";
import StRegSuccessfull from "../cms/StRegSuccessfull";
import OldDueList from "./OldDueList";
import OldDueCollection from "./OldDueCollection";
import ExamList from "../cms/ExamList";
import FeeOtherAmount from "../cms/FeeOtherAmount";
import ClassSettings from "../cms/ClassSettings";
import FeeSettings from "../cms/FeeSettings";
import ClassFee from "../cms/ClassFee";
import AddClassFee from "../cms/AddClassFee";
import FeeNameList from "../cms/FeeNameList";
import SpecialCaseStudent from "../cms/SpecialCaseStudent";
import SpecialCaseCreation from "../cms/SpecialCaseCreation";
import AdmissionQuery from "../cms/AdmissionQuery";
import AdmissionQueryDetails from "../cms/AdmissionQueryDetails";
import AdmissionLink from "../cms/AdmissionLink";
import RegistrationFee from "../cms/RegistrationFee";
import VendorRegistration from "../cms/VendorRegistration";
import MyVendor from "../cms/MyVendor";
import ExamTopperList from "../cms/ExamTopperList";
import ExamPaperSettings from "../cms/ExamPaperSettings";
import ExamClassResultStatus from "../cms/ExamClassResultStatus";
import ExamDetails from "../cms/ExamDetails";
import ExamClassResult from "../cms/ExamClassResult";
import ExamStudentResult from "../cms/ExamStudentResult";
import ExamReportCardList from "../cms/ExamReportCardList";
import ExamStudentReportCard from "../cms/ExamStudentReportCard";
import Attendance from "../cms/Attendance";
import PrintMaster from "../cms/PrintMaster";
import StudentIdCard from "../cms/StudentIdCard";
import ExamAdmitCard from "../cms/ExamAdmitCard";
import ExamDateSheet from "../cms/ExamDateSheet";
import ExamGuideline from "../cms/ExamGuideline";
import ExamHallTicket from "../cms/ExamHallTicket";
import ReportManagement from "./ReportManagement";
import ReportViewClass from "./ReportViewClass";
import DataCenter from "./DataCenter";
import StudentData from "./StudentData";
import ExamMarkingSystem from "../cms/ExamMarkingSystem";
import ExamMarkingSystemAnnual from "../cms/ExamMarkingSystemAnnual";
import TransactionPanel from "../cms/TransactionPanel";
import PrintableDocs from "../cms/PrintableDocs";
import COLORS from "../resources/colors/COLORS";
import Siblings from "../cms/Siblings";
import PrintableDocsAdmitCard from "../cms/PrintableDocsAdmitCard";
import PrintableDocsBlank from "../cms/PrintableDocsBlank";
import TransportRoutes from "../cms/TransportRoutes";
import TransportVehicles from "../cms/TransportVehicles";
import TransportStudent from "../cms/TransportStudent";
import TransportAddNewRoute from "../cms/TransportAddNewRoute";
import ExamCreation from "../cms/ExamCreation";
import ClassChange from "../cms/ClassChange";
import TransferCertificate from "../cms/TransferCertificate";
import TransferCertificateForm from "../cms/TransferCertificateForm";
import SrNumber from "../cms/SrNumber";
import EditStudentDetails from "../cms/EditStudentDetails";
import SubjectSettings from "../cms/SubjectSettings";
import SubjectList from "../cms/SubjectList";
import ClassSubject from "../cms/ClassSubject";
import ClassMarkStatus from "../cms/ClassMarkStatus";
import StudentMarkDetails from "../cms/StudentMarkDetails";
import ExamAddSingleStudentMark from "../cms/ExamAddSingleStudentMark";
import StaffProfile from "../cms/StaffProfile";
import BoardMediumSettings from "../cms/BoardMediumSettings";
import SessionSettings from "../cms/SessionSettings";
import RollNumberManagement from "../cms/RollNumberManagement";
import DepartmentSettings from "../cms/DepartmentSettings";
import FeeReceiptSettings from "../cms/FeeReceiptSettings";
import StudentManagement from "./StudentManagement";
import StaffManagement from "./StaffManagement";
import AddNewSibling from "../cms/AddNewSibling";
import DeleteStudent from "./DeleteStudent";
import DeleteStaff from "./DeleteStaff";
import DeleteExam from "./DeleteExam";
import ExamAddMarkStudentWise from "../cms/ExamAddMarkStudentWise";
import ExamAddMarkSubjectWise from "../cms/ExamAddMarkSubjectWise";
import PrintableDocsTopper from "../cms/PrintableDocsTopper";
import CompetitionAward from "../cms/CompetitionAward";
import ExamReportCardRankList from "../cms/ExamReportCardRankList";
import ExamReportCardAttendanceReport from "../cms/ExamReportCardAttendanceReport";
import SchoolWorkingDays from "../cms/SchoolWorkingDays";
import ExamReportCardCrossList from "../cms/ExamReportCardCrossList";
import PrintableDocsIdCard from "../cms/PrintableDocsIdCard";
import BloodGroup from "../cms/BloodGroup";
import Aadhaar from "../cms/Aadhaar";
import Gender from "../cms/Gender";
import MotherTongue from "../cms/MotherTongue";
import HeightAndWeight from "../cms/HeightAndWeight";
import BplBeneficiary from "../cms/BplBeneficiary";
import PhysicalDisability from "../cms/PhysicalDisability";
import StudentPen from "../cms/StudentPen";
import ExamSignSheet from "../cms/ExamSignSheet";
import TodayAttendance from "../cms/TodayAttendance";
import DemandSlip from "../cms/DemandSlip";
import UdiseForm from "../cms/UdiseForm.js";
import StudentAttendanceReport from "../cms/StudentAttendanceReport.js";

export default class AdminMain extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      login_type: "",
      adminModules: [],
      AllActiveModulesOfClerk: [],
      mModuleLMS: [],
      mModuleERP: [],
      mCurrentSession: [],
      mProductAccount: [],
    };
  }
  componentDidMount() {
    var mStaffData = SessionManager.getStaffDetails();
    this.state.login_type = mStaffData.login_type;

    if (this.state.login_type == process.env.REACT_APP_LOGIN_TYPE_ADMIN) {
      this.getAdminDashboard();
      //this.getAllActiveModulesOfClerk();
      //this.getCurrentSessionDetails();
    } else if (
      this.state.login_type == process.env.REACT_APP_LOGIN_TYPE_CLERK
    ) {
      this.getClerkDashboard();
    } else {
    }
  }
  getAdminDashboard() {
    this.setState({
      Loading: true,
    });
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getAdminMainDashboard.php?";
    const System_code = process.env.REACT_APP_SYSTEM_CODE;
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    console.log("URL>>>>>" + URL);
    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          system_code: System_code,
          CurrentVersionCode: "1.1",
        },
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          Loading: false,
        });
        let api_status = response.api_status;

        if (api_status == "OK") {
          this.setState({
            adminModules: response.dashboard.AdminModules,
            AllActiveModulesOfClerk: response.dashboard.ClerkAllActiveModules,
            mCurrentSession: response.dashboard.CurrentSession,
            mProductAccount: response.dashboard.product_account,
          });

          // console.log("response>>>>>"+this.state.adminModules)
          SessionManager.saveCurrentSession(this.state.mCurrentSession);
          if (this.state.mProductAccount[0].product_status !== "2") {
            myToast.notify_error("Your Account is not activated");
            this.setState({
              loggedIn: false,
            });
          }

          this.getAllActiveClerkModules();
          this.getLmsModules();
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      });
  }

  getClerkDashboard() {
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    var mStaffData = SessionManager.getStaffDetails();
    var STAFF_ID = mStaffData.staff_id;
    const System_code = process.env.REACT_APP_SYSTEM_CODE;

    const FUNCTION_NAME = "getDashBoard.php?";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    console.log("URL>>>>>" + URL);
    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          staff_id: STAFF_ID,
          system_code: System_code,
          CurrentVersionCode: "1.1",
        },
      })
      .then((res) => res.data)
      .then((response) => {
        let api_status = response.api_status;
        if (api_status == "OK") {
          this.setState({
            mCurrentSession: response.dashboard.CurrentSession,
            AllActiveModulesOfClerk: response.dashboard.privilege,
            mProductAccount: response.dashboard.product_account,
          });

          SessionManager.saveCurrentSession(this.state.mCurrentSession);
          if (this.state.mProductAccount[0].product_status !== "2") {
            myToast.notify_error("Your Account is not activated");
            this.setState({
              loggedIn: false,
            });
          }
          this.getAllActiveClerkModules();
          this.getLmsModules();
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }
      });
  }

  getAllActiveClerkModules() {
    this.state.mModuleERP = [];
    for (
      let index = 0;
      index < this.state.AllActiveModulesOfClerk.length;
      index++
    ) {
      const element = this.state.AllActiveModulesOfClerk[index];

      if (element.software_section === "ERP") {
        this.state.mModuleERP.push(element);
      }
    }
    this.setState({});
  }
  getLmsModules() {
    this.state.mModuleLMS = [];
    for (
      let index = 0;
      index < this.state.AllActiveModulesOfClerk.length;
      index++
    ) {
      const element = this.state.AllActiveModulesOfClerk[index];

      if (element.software_section === "LMS") {
        this.state.mModuleLMS.push(element);
      }
    }
    this.setState({});
  }

  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to="/" />;
    }
    if (this.state.Loading) {
      return <MyLoader />;
    } else {
      return (
        <div>
          <NavMenuAdmin />
          <Router>
            <switch>
              <Route
                path="/AdminMain"
                exact
                render={() => {
                  return (
                    <div
                      className="content"
                      style={{ background: "var(--white)" }}
                    >
                      <div className="content-header">
                        <div className="leftside-content-header">
                          <ul className="breadcrumbs">
                            <li>
                              {" "}
                              <Link to="/AdminMain">
                                {" "}
                                <i
                                  className="fa fa-home"
                                  aria-hidden="true"
                                />{" "}
                                &nbsp; School Managent ERP System{" "}
                              </Link>{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="animated fadeInUp bg_page_grey_rounded box_shadow"
                        style={{ "--mMargin": "5px" }}
                      >
                        <div className="row animated fadeInUp row-30-min-bg">
                          <div className="col-sm-12 col-lg-12 text-center">
                            <h3>EduOK ERP(Super Admin Modules)</h3>
                          </div>
                        </div>
                        <div className="row">
                          {this.state.adminModules.map((item, index) => (
                            <div className="col-sm-2 col-md-2 padding_0">
                              <div className="div-module-clerk">
                                <Link to={"/AdminMain/" + item.component_name}>
                                  <div className="gravity_center_vertical">
                                    <div
                                      className="bg-module-admin"
                                      style={{ height: "70px", width: "70px" }}
                                    >
                                      <img src={item.icon_img} alt={"icon"} />
                                    </div>
                                    <div
                                      className="gravity_center"
                                      style={{ height: "70px" }}
                                    >
                                      <p
                                        className="text-center"
                                        style={{
                                          padding: "5px",
                                          fontSize: "18px",
                                          color: COLORS.black,
                                        }}
                                      >
                                        {item.module_name}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="row animated fadeInUp row-30-min-bg">
                          <div className="col-sm-12 col-lg-12 text-center">
                            <h3>Admin/Clerk Modules</h3>
                          </div>
                        </div>
                        <div className="row">
                          {this.state.mModuleERP.map((item, index) => (
                            <div className="col-sm-2 col-md-2 padding_0">
                              <div className="div-module-clerk">
                                <Link
                                  to={
                                    "/AdminMain/" +
                                    item.component_name +
                                    "/" +
                                    item.module_id
                                  }
                                >
                                  <div className="gravity_center_vertical">
                                    <div
                                      className="bg-circle-clerk-module"
                                      style={{ height: "70px", width: "70px" }}
                                    >
                                      <img src={item.icon_img} alt={"icon"} />
                                    </div>
                                    <div
                                      className="gravity_center"
                                      style={{ height: "70px" }}
                                    >
                                      <p
                                        className="text-center"
                                        style={{
                                          padding: "5px",
                                          fontSize: "18px",
                                          color: COLORS.black,
                                        }}
                                      >
                                        {item.module_name}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="row animated fadeInUp row-30-min-bg">
                          <div className="col-sm-12 col-lg-12 text-center">
                            <h3>LMS(Learning Management System)</h3>
                          </div>
                        </div>
                        <div className="row">
                          {this.state.mModuleLMS.map((item, index) => (
                            <div className="col-sm-2 col-md-2 padding_0">
                              <div className="div-module-clerk">
                                <Link
                                  to={
                                    "/AdminMain/" +
                                    item.component_name +
                                    "/" +
                                    item.module_id
                                  }
                                >
                                  <div className="gravity_center_vertical">
                                    <div
                                      className="bg-circle-clerk-module"
                                      style={{ height: "70px", width: "70px" }}
                                    >
                                      <img src={item.icon_img} alt={"icon"} />
                                    </div>
                                    <div
                                      className="gravity_center"
                                      style={{ height: "70px" }}
                                    >
                                      <p
                                        className="text-center"
                                        style={{
                                          padding: "5px",
                                          fontSize: "18px",
                                          color: COLORS.black,
                                        }}
                                      >
                                        {item.module_name}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                }}
              />

              <Route path="/AdminMain/ModuleList" component={ModuleList} />
              <Route
                path="/AdminMain/StaffPrivilege"
                component={StaffPrivilege}
              />
              <Route path="/AdminMain/Security" component={Security} />
              <Route path="/AdminMain/Settlement" component={Settlement} />
              <Route path="/AdminMain/HelpDesk" component={HelpDesk} />
              <Route path="/AdminMain/Comment" component={Comment} />
              <Route
                path="/AdminMain/AdminNotification"
                component={AdminNotification}
              />
              <Route
                path="/AdminMain/RevenueManagement"
                component={RevenueManagement}
              />
              <Route
                path="/AdminMain/ManageRecords"
                component={ManageRecords}
              />
              <Route
                path="/AdminMain/LeaveManagementAdmin"
                component={LeaveManagementAdmin}
              />
              <Route
                path="/AdminMain/StaffList/:module_id"
                component={StaffList}
              />
              <Route path="/AdminMain/Settings" component={Settings} />
              <Route
                path="/AdminMain/ReportManagement"
                component={ReportManagement}
              />
              <Route
                path="/AdminMain/StudentManagement"
                component={StudentManagement}
              />
              <Route
                path="/AdminMain/StaffManagement"
                component={StaffManagement}
              />

              <Route
                path="/AdminMain/UpdateSchoolDetails"
                component={UpdateSchoolDetails}
              />
              <Route
                path="/AdminMain/SchoolProfile/:module_id"
                component={SchoolProfile}
              />
              <Route
                path="/AdminMain/FeeDashBoard/:module_id"
                component={FeeDashBoard}
              />
              <Route
                path="/AdminMain/AdmissionDashboard/:module_id"
                component={AdmissionDashboard}
              />
              <Route
                path="/AdminMain/AttendanceDashboard/:module_id"
                component={AttendanceDashboard}
              />
              <Route
                path="/AdminMain/TodayAttendance/:class_id/:section_id/:class_name/:section_name"
                component={TodayAttendance}
              />
              <Route
                path="/AdminMain/ClassList/:module_id"
                component={ClassList}
              />
              <Route
                path="/AdminMain/NoticeBoard/:module_id"
                component={NoticeBoard}
              />
              <Route path="/AdminMain/Holiday/:module_id" component={Holiday} />
              <Route path="/AdminMain/HomeWork" component={HomeWork} />
              <Route path="/AdminMain/Transport" component={Transport} />
              <Route
                path="/AdminMain/Academic/:module_id"
                component={Academic}
              />
              <Route
                path="/AdminMain/Notification/:module_id"
                component={Notification}
              />
              <Route
                path="/AdminMain/MyProfile/:module_id"
                component={MyProfile}
              />
              <Route
                path="/AdminMain/LeaveManagement"
                component={LeaveManagement}
              />
              <Route
                path="/AdminMain/LearningManagement"
                component={LearningManagement}
              />
              <Route
                path="/AdminMain/Accessories/:module_id"
                component={Accessories}
              />
              <Route path="/AdminMain/DataCenter" component={DataCenter} />
              <Route
                path="/AdminMain/TransactionPanel/:module_id"
                component={TransactionPanel}
              />
              <Route
                path="/AdminMain/PrintMaster/:module_id"
                component={PrintMaster}
              />

              <Route
                path="/AdminMain/StudentList/:class_id/:section_id"
                component={StudentList}
              />
              <Route
                path="/AdminMain/StudentDetails/:student_id"
                component={StudentDetails}
              />

              <Route
                path="/AdminMain/PaymentStatus/:student_id/:session_id/:session_name/:transaction_id"
                component={PaymentStatus}
              />
              <Route
                path="/AdminMain/AdvancedPayment/:student_id/:session_id/:current_due"
                component={AdvancedPayment}
              />
              <Route
                path="/AdminMain/PaymentHistory/:student_id/:session_id/:session_name"
                component={PaymentHistory}
              />
              <Route path="/AdminMain/FeeCard" component={FeeCard} />
              <Route
                path="/AdminMain/PaymentDetails/:transaction_id/:session_id/:student_id/:transaction_for"
                component={PaymentDetails}
              />

              <Route
                path="/AdminMain/FeeReceipt/:transaction_id/:student_id/:session_id"
                component={FeeReceipt}
              />

              <Route
                path="/AdminMain/FeeCollection"
                component={FeeCollection}
              />
              <Route path="/AdminMain/CurrentDue" component={CurrentDue} />
              <Route
                path="/AdminMain/TotalRecieved"
                component={TotalRecieved}
              />
              <Route path="/AdminMain/TotalDue" component={TotalDue} />
              <Route path="/AdminMain/TotalFee" component={TotalFee} />
              <Route path="/AdminMain/OldDueList/:tag" component={OldDueList} />

              <Route
                path="/AdminMain/StudentRegistration"
                component={StudentRegistration}
              />
              <Route
                path="/AdminMain/ChangePassword"
                component={ChangePassword}
              />
              <Route path="/AdminMain/AddHoliday" component={AddHoliday} />
              <Route
                path="/AdminMain/BatchShifting"
                component={BatchShifting}
              />
              <Route path="/AdminMain/EditStaff" component={EditStaff} />
              <Route
                path="/AdminMain/StaffRegistration"
                component={StaffRegistration}
              />
              <Route
                path="/AdminMain/UpdateStudentDetails"
                component={UpdateStudentDetails}
              />

              <Route path="/AdminMain/ManageNotice" component={ManageNotice} />
              <Route path="/AdminMain/AddNotice" component={AddNotice} />
              <Route
                path="/AdminMain/SendNotification"
                component={SendNotification}
              />
              <Route
                path="/AdminMain/Acknowledgement/:student_id/:Session_id"
                component={Acknowledgement}
              />
              <Route
                path="/AdminMain/StudentRegSuccess/:Enrollment_no/:session_id"
                component={StRegSuccessfull}
              />
              <Route
                path="/AdminMain/OldDueCollection"
                component={OldDueCollection}
              />
              <Route path="/AdminMain/ExamList" component={ExamList} />
              <Route
                path="/AdminMain/FeeOtherAmount"
                component={FeeOtherAmount}
              />

              <Route path="/AdminMain/FeeSettings" component={FeeSettings} />
              <Route
                path="/AdminMain/ClassSettings"
                component={ClassSettings}
              />
              <Route path="/AdminMain/ClassFee" component={ClassFee} />
              <Route
                path="/AdminMain/AddClassFee/:selected_class_pos"
                component={AddClassFee}
              />
              <Route path="/AdminMain/FeeNameList" component={FeeNameList} />
              <Route
                path="/AdminMain/SpecialCaseStudent"
                component={SpecialCaseStudent}
              />
              <Route
                path="/AdminMain/SpecialCaseCreation"
                component={SpecialCaseCreation}
              />
              <Route
                path="/AdminMain/AdmissionQuery"
                component={AdmissionQuery}
              />
              <Route
                path="/AdminMain/AdmissionQueryDetails"
                component={AdmissionQueryDetails}
              />
              <Route
                path="/AdminMain/AdmissionLink"
                component={AdmissionLink}
              />
              <Route
                path="/AdminMain/RegistrationFee"
                component={RegistrationFee}
              />
              <Route path="/AdminMain/MyVendor" component={MyVendor} />
              <Route
                path="/AdminMain/VendorRegistration"
                component={VendorRegistration}
              />
              <Route
                path="/AdminMain/ExamTopperList"
                component={ExamTopperList}
              />
              <Route
                path="/AdminMain/ExamPaperSettings"
                component={ExamPaperSettings}
              />
              <Route
                path="/AdminMain/ExamClassResultStatus/:exam_id/:session_id/:exam_name/:exam_date"
                component={ExamClassResultStatus}
              />
              <Route
                path="/AdminMain/ExamDetails/:exam_id/:session_id/:exam_name/:exam_status"
                component={ExamDetails}
              />
              <Route
                path="/AdminMain/ExamClassResult/:exam_id/:session_id/:selected_position_class/:selected_position_subject/:exam_name/:exam_date"
                component={ExamClassResult}
              />
              <Route
                path="/AdminMain/ExamStudentResult/:exam_id/:session_id/:student_id/:class_id/:section_id/:exam_name/:exam_date"
                component={ExamStudentResult}
              />
              <Route
                path="/AdminMain/ExamReportCardList"
                component={ExamReportCardList}
              />
              <Route
                path="/AdminMain/ExamStudentReportCard/:report_card_id/:exam_id/:session_id/:student_id"
                component={ExamStudentReportCard}
              />

              <Route
                path="/AdminMain/StudentIdCard"
                component={StudentIdCard}
              />
              <Route
                path="/AdminMain/ExamAdmitCard/:exam_id/:session_id/:exam_name/:exam_status"
                component={ExamAdmitCard}
              />
              <Route
                path="/AdminMain/ExamDateSheet/:exam_id/:session_id/:exam_name"
                component={ExamDateSheet}
              />
              <Route
                path="/AdminMain/ExamGuideline/:exam_id/:session_id/:exam_name"
                component={ExamGuideline}
              />
              <Route
                path="/AdminMain/ExamHallTicket/:exam_id/:session_id/:exam_name/:exam_status"
                component={ExamHallTicket}
              />
              <Route
                path="/AdminMain/ReportViewClass/:document_type/"
                component={ReportViewClass}
              />
              <Route
                path="/AdminMain/StudentData/:file_directory/:file_name/"
                component={StudentData}
              />
              <Route
                path="/AdminMain/ExamMarkingSystem/:exam_id/:session_id"
                component={ExamMarkingSystem}
              />
              <Route
                path="/AdminMain/ExamMarkingSystemAnnual/:report_card_id/:session_id"
                component={ExamMarkingSystemAnnual}
              />
              <Route
                path="/AdminMain/PrintableDocs/:document_type/"
                component={PrintableDocs}
              />
              <Route
                path="/AdminMain/PrintableDocsIdCard/:document_type/"
                component={PrintableDocsIdCard}
              />
              <Route path="/AdminMain/Siblings" component={Siblings} />
              <Route
                path="/AdminMain/PrintableDocsAdmitCard/:document_type/"
                component={PrintableDocsAdmitCard}
              />
              <Route
                path="/AdminMain/PrintableDocsBlank/:document_type/"
                component={PrintableDocsBlank}
              />

              <Route
                path="/AdminMain/TransportRoutes"
                component={TransportRoutes}
              />
              <Route
                path="/AdminMain/TransportVehicles"
                component={TransportVehicles}
              />
              <Route
                path="/AdminMain/TransportStudent"
                component={TransportStudent}
              />
              <Route
                path="/AdminMain/TransportAddNewRoute"
                component={TransportAddNewRoute}
              />
              <Route path="/AdminMain/ExamCreation" component={ExamCreation} />
              <Route path="/AdminMain/ClassChange" component={ClassChange} />
              <Route
                path="/AdminMain/TransferCertificate"
                component={TransferCertificate}
              />
              <Route
                path="/AdminMain/TransferCertificateForm"
                component={TransferCertificateForm}
              />
              <Route path="/AdminMain/SrNumber" component={SrNumber} />
              <Route
                path="/AdminMain/EditStudentDetails/:student_id"
                component={EditStudentDetails}
              />
              <Route
                path="/AdminMain/FeeReceiptSettings"
                component={FeeReceiptSettings}
              />
              <Route
                path="/AdminMain/DepartmentSettings"
                component={DepartmentSettings}
              />
              <Route
                path="/AdminMain/RollNumberManagement"
                component={RollNumberManagement}
              />
              <Route
                path="/AdminMain/SubjectSettings"
                component={SubjectSettings}
              />
              <Route path="/AdminMain/SubjectList" component={SubjectList} />
              <Route
                path="/AdminMain/SessionSettings"
                component={SessionSettings}
              />
              <Route
                path="/AdminMain/BoardMediumSettings"
                component={BoardMediumSettings}
              />
              <Route path="/AdminMain/ClassSubject" component={ClassSubject} />

              <Route
                path="/AdminMain/ClassMarkStatus"
                component={ClassMarkStatus}
              />
              <Route
                path="/AdminMain/StudentMarkDetails"
                component={StudentMarkDetails}
              />
              <Route
                path="/AdminMain/ExamAddSingleStudentMark"
                component={ExamAddSingleStudentMark}
              />
              <Route
                path="/AdminMain/StaffProfile/:Staff_id"
                component={StaffProfile}
              />
              <Route
                path="/AdminMain/AddNewSibling"
                component={AddNewSibling}
              />
              <Route
                path="/AdminMain/DeleteStudent"
                component={DeleteStudent}
              />
              <Route path="/AdminMain/DeleteStaff" component={DeleteStaff} />
              <Route path="/AdminMain/DeleteExam" component={DeleteExam} />
              <Route
                path="/AdminMain/ExamAddMarkStudentWise/:exam_id/:session_id/:exam_name/"
                component={ExamAddMarkStudentWise}
              />
              <Route
                path="/AdminMain/ExamAddMarkSubjectWise/:exam_id/:session_id/:exam_name/"
                component={ExamAddMarkSubjectWise}
              />
              <Route
                path="/AdminMain/PrintableDocsTopper/:document_type/"
                component={PrintableDocsTopper}
              />
              <Route
                path="/AdminMain/CompetitionAward/:document_type/"
                component={CompetitionAward}
              />
              <Route
                path="/AdminMain/ExamReportCardRankList/:report_card_id/:session_id"
                component={ExamReportCardRankList}
              />
              <Route
                path="/AdminMain/ExamReportCardAttendanceReport/:report_card_id/:session_id/:manual_attendance/"
                component={ExamReportCardAttendanceReport}
              />
              <Route
                path="/AdminMain/SchoolWorkingDays/:report_card_id/:session_id"
                component={SchoolWorkingDays}
              />
              <Route
                path="/AdminMain/ExamReportCardCrossList/:report_card_id/:session_id"
                component={ExamReportCardCrossList}
              />
              <Route path="/AdminMain/Aadhaar" component={Aadhaar} />

              <Route path="/AdminMain/BloodGroup" component={BloodGroup} />
              <Route path="/AdminMain/Gender" component={Gender} />
              <Route path="/AdminMain/MotherTongue" component={MotherTongue} />
              <Route
                path="/AdminMain/HeightAndWeight"
                component={HeightAndWeight}
              />
              <Route
                path="/AdminMain/BplBeneficiary"
                component={BplBeneficiary}
              />
              <Route
                path="/AdminMain/PhysicalDisability"
                component={PhysicalDisability}
              />
              <Route path="/AdminMain/StudentPen" component={StudentPen} />
              <Route
                path="/AdminMain/ExamSignSheet/:document_type/"
                component={ExamSignSheet}
              />
              <Route
                path="/AdminMain/DemandSlip/:document_type/"
                component={DemandSlip}
              />

              <Route
                path="/AdminMain/UdiseForm/:document_type/"
                component={UdiseForm}
              />
              <Route
                path="/AdminMain/StudentAttendanceReport/:document_type/"
                component={StudentAttendanceReport}
              />
            </switch>
          </Router>
        </div>
      );
    }
  }
}

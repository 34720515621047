import React from "react";
import { Pie } from "react-chartjs-2";

const AttendancePieChart = (props) => {
  const data = {
    labels: [
      `P (${props.Present})`,
      `A (${props.Absent})`,
      `L (${props.Leave})`,
    ],
    datasets: [
      {
        label: "Attendance",
        data: [props.Present, props.Absent, props.Leave],
        backgroundColor: [
          "rgba(75, 192, 192, 0.6)", // Green
          "rgba(255, 99, 132, 0.6)", // Red
          "rgba(255, 206, 86, 0.6)", // Yellow
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)", // Green border
          "rgba(255, 99, 132, 1)", // Red border
          "rgba(255, 206, 86, 1)", // Yellow border
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "blue", // Change legend label color
          font: {
            size: 14, // Set font size for the legend
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const dataset = tooltipItem.dataset;
            const total = dataset.data.reduce((acc, value) => acc + value, 0);
            const value = dataset.data[tooltipItem.dataIndex];
            const percentage = ((value / total) * 100).toFixed(1);
            return `${
              tooltipItem.label || tooltipItem.raw
            }: ${value} (${percentage}%)`;
          },
        },
      },
      datalabels: {
        color: "#000", // Set label color
        font: {
          weight: "bold",
        },
        formatter: (value, context) => {
          return value; // Display raw value inside the chart
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <div style={{ width: "100%", height: "120px" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          maxHeight: "500px",
        }}
      >
        <Pie data={data} options={options} />
      </div>
    </div>
  );
};

export default AttendancePieChart;

import React, { useState, useEffect } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import { Modal } from "react-bootstrap";
import COLORS from "../resources/colors/COLORS";
import myToast from "../Utils/MyToast";

const MdlSelectExamClassAndMultipleStudent = ({
  action,
  action2,
  exam_id,
  session_id,
}) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [loadingStudent, setLoadingStudent] = useState(false);
  const [participatingClasses, setParticipatingClasses] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [selectedClassIndex, setSelectedClassIndex] = useState(0);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [classId, setClassId] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    localStorage.setItem("SelectedMultipleStudent", JSON.stringify([]));
    fetchParticipatingClasses();
  }, []); // Runs once when the component mounts

  const fetchParticipatingClasses = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}getExamParticipatingClass.php`;

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          session_id,
          exam_id,
        },
      })
      .then((response) => {
        const data = response.data;
        setLoading(false);
        if (data.api_status === "OK") {
          setParticipatingClasses(data.data);
          selectClass(0); // Set initial class data
        } else {
          myToast.notify_warning(data.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching classes:", error);
      });
  };

  const selectClass = (position) => {
    const selectedClass = participatingClasses[position];
    if (selectedClass) {
      setClassId(selectedClass.class_id);
      setSectionId(selectedClass.section_id || "No");
      setSelectedClassIndex(position);
      fetchStudentList(selectedClass.class_id, selectedClass.section_id);
    }
  };

  const fetchStudentList = (classId, sectionId) => {
    setLoadingStudent(true);
    setStudentList([]);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}getCurrentStudent.php`;

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          class_id: classId,
          section_id: sectionId,
          session_id: session_id,
        },
      })
      .then((response) => {
        const data = response.data;
        setLoadingStudent(false);
        if (data.api_status === "OK") {
          setStudentList(data.data);
        } else {
          myToast.notify_warning(data.api_remark);
        }
      })
      .catch((error) => {
        setLoadingStudent(false);
        console.error("Error fetching students:", error);
      });
  };

  const handleStudentSelection = (e, index) => {
    const isChecked = e.target.checked;
    const student = studentList[index];
    let updatedSelectedStudents = [...selectedStudents];

    if (isChecked) {
      updatedSelectedStudents.push(student);
    } else {
      updatedSelectedStudents = updatedSelectedStudents.filter(
        (s) => s.student_enrollment_no !== student.student_enrollment_no
      );
    }

    setSelectedStudents(updatedSelectedStudents);
    localStorage.setItem(
      "SelectedMultipleStudent",
      JSON.stringify(updatedSelectedStudents)
    );
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      setSelectedStudents(studentList);
      localStorage.setItem(
        "SelectedMultipleStudent",
        JSON.stringify(studentList)
      );
    } else {
      setSelectedStudents([]);
      localStorage.setItem("SelectedMultipleStudent", JSON.stringify([]));
    }
    setSelectAll(!selectAll);
  };

  return (
    <Modal
      show={show}
      onHide={action}
      animation={false}
      centered
      size="lg"
      style={{ opacity: 1 }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Student</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <MyLoader />
        ) : (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0px 5px 0px 5px",
              }}
            >
              <h5 style={{ marginRight: "10px" }}>Participating Class:</h5>
              <button
                className="btn btn-primary"
                onClick={handleSelectAll}
                style={{ marginLeft: "auto" }}
              >
                {selectAll ? "Deselect All" : "Select All"}
              </button>
            </div>

            <div
              className="row bg_card_primary"
              style={{ padding: "5px", margin: "0px 5px 0px 5px" }}
            >
              {participatingClasses.map((item, index) => (
                <div
                  className="col-sm-2 col-md-2"
                  style={{ padding: "0px 10px 5px 10px" }}
                  key={index}
                >
                  <div
                    className={
                      selectedClassIndex === index
                        ? "bg_class_select gravity_center"
                        : "bg_class_deselect gravity_center"
                    }
                    onClick={() => selectClass(index)}
                    style={{ cursor: "pointer" }}
                  >
                    <p style={{ fontSize: 14 }}>
                      {item.section_id === "No"
                        ? item.class_name
                        : `${item.class_name}:${item.section_name}`}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            {loadingStudent ? (
              <MyLoader />
            ) : (
              <>
                {studentList.map((student, index) => (
                  <div className="col-sm-6 col-md-6 p-0" key={index}>
                    <div
                      className="row bg_card_blue_outline"
                      style={{ margin: "5px 15px 10px 15px", height: "90px" }}
                    >
                      <div
                        className="col-sm-3 bg_card_blue gravity_center"
                        style={{ padding: "1px", height: "85px" }}
                      >
                        <div
                          className="gravity_center"
                          style={{
                            padding: "0px",
                            margin: "2px",
                            height: "80px",
                            width: "80px",
                          }}
                        >
                          <div className="circular_image">
                            <img src={student.student_img_path} alt="" />
                            <p className="student_id gravity_center">
                              <small>{student.student_enrollment_no}</small>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-sm-6"
                        style={{ padding: "5px 10px", height: "85px" }}
                      >
                        <h5>{student.stu_name}</h5>
                        <p className="mb-0">
                          Father: <span>{student.father_name}</span>
                        </p>
                      </div>
                      <div
                        className="col-sm-3 padding_0 gravity_center"
                        style={{ height: "85px" }}
                      >
                        <div
                          className="text-center bg_card_blue_outline width_fill_parent"
                          style={{ margin: "2px" }}
                        >
                          <p className="mb-0">
                            Roll No: {student.student_roll_no}
                          </p>
                          <div
                            className="st-list-roll-n"
                            style={{
                              background: "#fff",
                              width: "35px",
                              height: "35px",
                              padding: "2px",
                              borderRadius: "50%",
                              margin: "2px auto",
                              fontSize: "20px",
                            }}
                          >
                            <input
                              type="checkbox"
                              className="ch-in cursor"
                              checked={selectedStudents.some(
                                (s) =>
                                  s.student_enrollment_no ===
                                  student.student_enrollment_no
                              )}
                              onChange={(e) => handleStudentSelection(e, index)}
                            />
                          </div>
                          <h6>
                            Class: <span>{student.class_name}</span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}

            <div
              className="col-sm-12 text-center cursor"
              style={{ margin: "15px auto" }}
              onClick={action2}
            >
              <h3
                style={{
                  background: COLORS.primary_color_shade5,
                  color: "#fff",
                  padding: "5px",
                }}
              >
                Done
              </h3>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MdlSelectExamClassAndMultipleStudent;

import React, { Component, createRef } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import COLORS from "../resources/colors/COLORS";
import SessionManager from "../Utils/SessionManager";
import myToast from "../Utils/MyToast";
import uploadIcon from "../resources/images/upload-icon.png";
import MdlSelectSession from "../dialog/MdlSelectSession";
export default class SelectClass extends React.PureComponent {
  constructor(props) {
    super(props);
    this.slt_board = createRef();
    this.slt_medium = createRef();
    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      isOpenSessionDialog: false,
      loggedIn: true,
      Loading: false,
      LoadingStudent: false,
      classBoard: [],
      classMedium: [],
      ClassSection: [],
      mFilteredClassList: [],
      mFilteredClassSectionList: [],
      mClassData: [],
      CLASS_SELECTED_POSITION: 0,
      SESSION_STATUS: "",
      SESSION_ID: "",
      SESSION_NAME: "",
      START_DATE: "",
      END_DATE: "",
      re_render: 0,
    };
    this.HandleSelectedSession = this.HandleSelectedSession.bind(this);
    this.HandleCloseSessionDialog = this.HandleCloseSessionDialog.bind(this);
  }

  ChangeSession = () => {
    this.HandleShowSessionDialog();
  };

  HandleShowSessionDialog = () => {
    this.setState({
      isOpenSessionDialog: !this.state.isOpenSessionDialog,
    });
  };

  HandleCloseSessionDialog = () => {
    this.setState({
      isOpenSessionDialog: false,
    });
  };
  HandleSelectedSession = (
    session_id,
    session_name,
    session_status,
    start_date,
    end_date
  ) => {
    this.state.SESSION_ID = session_id;
    this.state.SESSION_NAME = session_name;
    this.state.SESSION_STATUS = session_status;
    this.state.START_DATE = start_date;
    this.state.END_DATE = end_date;
    this.setState({
      isOpenSessionDialog: false,
    });
    this.getClass();
  };

  componentDidMount() {
    var mSession = SessionManager.getCurrentSession();
    this.state.SESSION_ID = mSession[0].session_id;
    this.state.SESSION_NAME = mSession[0].session_name;
    this.state.SESSION_STATUS = mSession[0].status;
    this.state.START_DATE = mSession[0].start_date;
    this.state.END_DATE = mSession[0].end_date;

    if (this.props.selected_position) {
      this.state.CLASS_SELECTED_POSITION = Number(this.props.selected_position);
      //alert( this.state.CLASS_SELECTED_POSITION);
    } else {
      this.state.CLASS_SELECTED_POSITION = 0;
    }
    this.getClass();
  }

  selectClass = (position) => {
    this.state.CLASS_SELECTED_POSITION = position;
    var class_id =
      this.state.mFilteredClassSectionList[this.state.CLASS_SELECTED_POSITION][
        "class_id"
      ];
    var class_name =
      this.state.mFilteredClassSectionList[this.state.CLASS_SELECTED_POSITION][
        "class_name"
      ];
    var section_id =
      this.state.mFilteredClassSectionList[this.state.CLASS_SELECTED_POSITION][
        "section_id"
      ];
    var section_name =
      this.state.mFilteredClassSectionList[this.state.CLASS_SELECTED_POSITION][
        "section_name"
      ];
    var session_id =
      this.state.mFilteredClassSectionList[this.state.CLASS_SELECTED_POSITION][
        "session_id"
      ];
    var session_name = this.state.SESSION_NAME;

    this.setState({
      re_render: this.state.re_render++,
    });
    this.props.action(
      class_id,
      section_id,
      session_id,
      position,
      class_name,
      section_name,
      session_name,
      this.state.START_DATE,
      this.state.END_DATE
    );
  };

  getClass() {
    this.setState({
      Loading: true,
    });

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getClass.php";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    console.log("URL>>>>>" + URL);
    // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);

    axios
      .get(URL, {
        params: { school_code: SCHOOL_CODE, session_id: this.state.SESSION_ID },
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          Loading: false,
        });
        // console.log("Response2>>>>>"+response);
        let api_status = response.api_status;
        //(JSON.stringify(response));

        if (api_status == "OK") {
          this.setState({
            mClassData: response.data.class,
            classBoard: response.data.board,
            classMedium: response.data.medium,
            ClassSection: response.data.class[0].section,
          });
          this.getClassList();
          //console.log("response>>>>>"+this.state.mClassData);
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        console.log("error>>>>>" + error);
      });
  }

  onBoardChange = () => {
    this.state.CLASS_SELECTED_POSITION = 0;
    this.getClassList();
  };
  onMediumChange = () => {
    this.state.CLASS_SELECTED_POSITION = 0;
    this.getClassList();
  };
  getClassList() {
    let board_id =
      this.slt_board.current.selectedOptions[0].getAttribute("board_id");
    let medium_id =
      this.slt_medium.current.selectedOptions[0].getAttribute("medium_id");
    // alert(board_id);
    // alert(medium_id);

    this.state.mFilteredClassSectionList = [];
    var mFilteredClassList = [];
    for (var i = 0; i < this.state.mClassData.length; i++) {
      let item = this.state.mClassData[i];
      if (item.board_id === board_id && item.medium_id === medium_id) {
        mFilteredClassList.push(item);
      }
    }
    for (var i = 0; i < mFilteredClassList.length; i++) {
      let item = mFilteredClassList[i];
      let SECTION = item.section;
      if (SECTION === "No") {
        item["section_id"] = "No";
        this.state.mFilteredClassSectionList.push(item);
      } else {
        var tempSection = [];
        tempSection = item.section;
        for (var j = 0; j < tempSection.length; j++) {
          let item2 = tempSection[j];
          item2["board_id"] = board_id;
          item2["board_name"] = item.board_name;
          item2["medium_id"] = medium_id;
          item2["medium_name"] = item.medium_name;
          this.state.mFilteredClassSectionList.push(item2);
        }
      }
    }
    this.setState({
      re_render: this.state.re_render++,
    });
    if (this.state.mFilteredClassSectionList.length !== 0) {
      var class_id =
        this.state.mFilteredClassSectionList[
          this.state.CLASS_SELECTED_POSITION
        ]["class_id"];
      var class_name =
        this.state.mFilteredClassSectionList[
          this.state.CLASS_SELECTED_POSITION
        ]["class_name"];
      var section_id =
        this.state.mFilteredClassSectionList[
          this.state.CLASS_SELECTED_POSITION
        ]["section_id"];
      var section_name =
        this.state.mFilteredClassSectionList[
          this.state.CLASS_SELECTED_POSITION
        ]["section_name"];
      var session_id =
        this.state.mFilteredClassSectionList[
          this.state.CLASS_SELECTED_POSITION
        ]["session_id"];
      var session_name = this.state.SESSION_NAME;
      this.props.action(
        class_id,
        section_id,
        session_id,
        this.state.CLASS_SELECTED_POSITION,
        class_name,
        section_name,
        session_name
      );
    }
  }
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to="/Login" />;
    }
    if (this.state.Loading) {
      return <MyLoader />;
    } else {
      return (
        <div>
          <div className="row">
            <div className="col-sm-12 col-md-12 bg_card_blue">
              <Link to="#" onClick={() => this.ChangeSession()}>
                <p
                  style={{
                    padding: "1px 5px 1px 5px",
                    fontSize: "18px",
                    textAlign: "center",
                    color: COLORS.white,
                  }}
                >
                  {" "}
                  Session:
                  {this.state.SESSION_NAME +
                    "(" +
                    this.state.SESSION_STATUS +
                    ")"}
                  <span style={{ float: "right" }}>
                    {" "}
                    <img
                      src={uploadIcon}
                      alt="upload image"
                      className="bg_card_blue_outline"
                      style={{ height: "25px", width: "25px" }}
                    />{" "}
                  </span>{" "}
                </p>
              </Link>
            </div>
          </div>
          <div className="bg_card_blue" style={{ padding: "5px 5px 10px 5px" }}>
            <div className="row m-0">
              <div className="col-sm-6 col-md-6">
                <div className="">
                  <h5>Select Boards:</h5>
                  <select
                    className="select-field"
                    onChange={this.onBoardChange}
                    ref={this.slt_board}
                  >
                    {this.state.classBoard.map((item, index) => (
                      <option board_id={item.board_id}>
                        {item.board_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-6 col-md-6">
                <div className="">
                  <h5>Select Medium</h5>
                  <select
                    className="select-field"
                    onChange={this.onMediumChange}
                    ref={this.slt_medium}
                  >
                    {this.state.classMedium.map((item, index) => (
                      <option medium_id={item.medium_id}>
                        {item.medium_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="row" style={{ padding: "0px 15px 0px 15px" }}>
              {this.state.mFilteredClassSectionList.map((item, index) => (
                <div
                  className="col-sm-1 col-md-1"
                  style={{ padding: "0px 2px 5px 2px" }}
                >
                  <div
                    className={
                      this.state.CLASS_SELECTED_POSITION === index
                        ? "bg_class_select gravity_center"
                        : "bg_class_deselect gravity_center"
                    }
                    onClick={(e) => this.selectClass(index)}
                    style={{ cursor: "pointer" }}
                  >
                    <p style={{ fontSize: 14 }}>
                      {" "}
                      {item.section === "No"
                        ? item.class_name
                        : item.class_name + ":" + item.section_name}{" "}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {this.state.isOpenSessionDialog === true ? (
            <MdlSelectSession
              action={this.HandleCloseSessionDialog}
              action2={this.HandleSelectedSession}
            ></MdlSelectSession>
          ) : (
            ""
          )}
        </div>
      );
    }
  }
}

import React, { useEffect, useState } from "react";
import "../css/Admin_css/admin-customize.css";
import boyImage from "../img/boy.jpg";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import MonthAttendance from "./MonthAttendance";
import myToast from "../Utils/MyToast";
import COLORS from "../resources/colors/COLORS";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";

const TodayAttendance = (props) => {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("token") !== null
  );
  const [loading, setLoading] = useState(false);
  const [mAttendanceToday, setmAttendanceToday] = useState([]);
  const [students, setStudents] = useState([]);
  const [CLASS_NAME, setClassName] = useState();
  const [CLASS_ID, setClassId] = useState();
  const [SECTION_ID, setSectionId] = useState();
  const [SECTION_NAME, setSectionName] = useState();
  const [DATE, setDATE] = useState();
  const [DATE_DD_MM_YY, setDate2] = useState();
  const [FLAG_PRESENT_ALL, setFLAG_PRESENT_ALL] = useState(true);

  useEffect(() => {
    // alert("hii");
    // alert(props.match.params.class_name);
    setClassName(props.match.params.class_name);
    setSectionName(props.match.params.section_name);
    var class_id = props.match.params.class_id;
    var section_id = props.match.params.section_id;
    setClassId(class_id);
    setSectionId(section_id);

    const SELECTED_DATE = new Date();
    const day = SELECTED_DATE.getDate();
    const month = SELECTED_DATE.getMonth() + 1; // Months are 0-indexed
    const year = SELECTED_DATE.getFullYear();
    const strMonth = month > 9 ? month.toString() : "0" + month;
    const strDay = day > 9 ? day.toString() : "0" + day;
    const strDATE = `${year}-${strMonth}-${strDay}`;
    setDate2(`${strDay}-${strMonth}-${year}`);
    setDATE(strDATE);
    getClassAttendanceStatus(class_id, section_id, year, strMonth, strDATE);

    //alert(MONTH);
  }, []);

  useEffect(() => {
    //alert(JSON.stringify(mAttendanceToday));
    if (mAttendanceToday.length > 0) {
      if (!mAttendanceToday[0]["Taken"]) {
        getStudent();
      }
    }
  }, [mAttendanceToday]);

  const getClassAttendanceStatus = (
    class_id,
    section_id,
    year,
    month,
    date
  ) => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const session = SessionManager.getCurrentSession();

    const FUNCTION_NAME = "getParticularClassAttendanceToday.php?";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;
    const params = {
      school_code: SCHOOL_CODE,
      class_id: class_id,
      section_id: section_id,
      attendance_date: date,
      month: month,
      year: year,
    };

    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);

    axios
      .get(URL, {
        params: params,
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);

        if (response.api_status === "OK") {
          setmAttendanceToday(response.data);
        } else {
          //myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const getStudent = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const session = SessionManager.getCurrentSession();

    const FUNCTION_NAME = "getStudentByDate.php?";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;
    const params = {
      school_code: SCHOOL_CODE,
      class_id: CLASS_ID,
      section_id: SECTION_ID,
      date: DATE,
    };

    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);

    axios
      .get(URL, {
        params: params,
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);

        if (response.api_status === "OK") {
          setStudents(response.data);
          initAttendance(FLAG_PRESENT_ALL);
        } else {
          //myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const initAttendance = (PRESENT_ALL) => {
    if (PRESENT_ALL) {
      // Mark all students as present

      setStudents((prevStudents) =>
        prevStudents.map((student) => ({
          ...student,
          attendence: student.OnLeave === "Yes" ? "L" : "P",
        }))
      );
    } else {
      // Mark all students as absent
      setStudents((prevStudents) =>
        prevStudents.map((student) => ({
          ...student,
          attendence: student.OnLeave === "Yes" ? "L" : "A",
        }))
      );
    }
  };

  const toggleStatus = (id) => {
    setStudents(
      students.map((student) =>
        student.student_enrollment_no === id
          ? { ...student, attendence: student.attendence === "P" ? "A" : "P" }
          : student
      )
    );
  };
  const handleToggle = (event) => {
    setFLAG_PRESENT_ALL(event.target.checked);
    initAttendance(event.target.checked);
  };

  return (
    <Router>
      <switch>
        <Route
          path={
            "/AdminMain/TodayAttendance/:class_id/:section_id/:class_name/:section_name"
          }
          exact
          render={() => {
            return (
              <div className="content">
                <div className="content-header">
                  <div className="leftside-content-header">
                    <ul className="breadcrumbs">
                      <li>
                        <i className="fa fa-home" aria-hidden="true" />
                        <a href="#">Today Attendance</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="bg_page_white_rounded box_shadow">
                  <div className="student_Attendance-container">
                    {loading && <MyLoader />}
                    <header className="student_Attendance-header">
                      <h1>Attendance Today</h1>
                      <Link
                        to={
                          SECTION_ID === "No"
                            ? "/AdminMain/MonthAttendance/" +
                              CLASS_ID +
                              "/" +
                              "No" +
                              "/" +
                              CLASS_NAME +
                              "/" +
                              "null"
                            : "/AdminMain/Attendance/" +
                              CLASS_ID +
                              "/" +
                              SECTION_ID +
                              "/" +
                              CLASS_NAME +
                              "/" +
                              SECTION_NAME
                        }
                      >
                        <button>Month Attendance</button>
                      </Link>
                    </header>
                    <div className="student_Attendance-content">
                      <div className="student_Attendance-class-info">
                        <h2 className="student_Attendance-summary-item1">
                          Class: {CLASS_NAME}{" "}
                        </h2>
                        <div className="student_Attendance-date-switch">
                          <div className="student_Attendance-date-info">
                            <span>This Month</span>
                            <span>Date:{DATE_DD_MM_YY}</span>
                          </div>

                          <label className="student_Attendance-switch">
                            <input
                              type="checkbox"
                              checked={FLAG_PRESENT_ALL}
                              onChange={handleToggle}
                            />
                            <span className="student_Attendance-slider round"></span>
                          </label>
                        </div>
                      </div>
                      {mAttendanceToday.length > 0 &&
                      mAttendanceToday[0]["status"] === "Taken" ? (
                        <div></div>
                      ) : (
                        <div className="student_Attendance-student-list">
                          {students.map((student) => (
                            <div
                              key={student.id}
                              className={`student_Attendance-student-card ${
                                student.attendence === "A" ? "absent" : ""
                              }`}
                              onClick={() =>
                                toggleStatus(student.student_enrollment_no)
                              }
                            >
                              <div className="student_Attendance-student-info">
                                <img
                                  src={student.student_img_path}
                                  alt={student.name}
                                />
                                <div className="student_Attendance-student-details">
                                  <h3>{student.stu_name}</h3>
                                  <p>S/O: {student.father_name}</p>
                                </div>
                              </div>
                              <div className="student_Attendance-student-status">
                                <div className="student_Attendance-roll-number">
                                  Roll No: {student.student_roll_no}
                                </div>
                                <span
                                  className={`student_Attendance-status-circle ${
                                    student.attendence === "A" ? "absent" : ""
                                  }`}
                                >
                                  {student.attendence}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}

                      <div className="student_Attendance-attendance-summary">
                        <p className="student_Attendance-summary-item">
                          Total Students: {students.length}
                        </p>
                        <p className="student_Attendance-summary-item">
                          Present:{" "}
                          {students.filter((s) => s.attendence === "P").length}
                        </p>

                        <p className="student_Attendance-summary-item">
                          Leave:{""}{" "}
                          {students.filter((s) => s.attendence === "L").length}
                        </p>
                        <p className="student_Attendance-summary-item">
                          Absent:{" "}
                          {students.filter((s) => s.attendence === "A").length}
                        </p>
                      </div>
                      <button className="student_Attendance-submit-button">
                        Submit Attendance
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        />
        <Route
          path="/AdminMain/MonthAttendance/:class_id/:section_id/:class_name/:section_name"
          component={MonthAttendance}
        />
      </switch>
    </Router>
  );
};

export default TodayAttendance;

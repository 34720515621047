import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, Redirect, Route, Switch } from 'react-router-dom';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import myToast from '../Utils/MyToast';
import boyImage from '../img/boy.jpg';
import '../css/Admin_css/admin-customize.css';
import StudentsAttendance from './StudentsAttendance';
const MonthAttendance = (props) => {
  const [students, setStudents] = useState([
    { id: 1, rollNumber: "1", name: "Ayan Das", parent: "Rajib Das", status: "P" },
    { id: 2, rollNumber: "2", name: "Adi Hossen", parent: "Nur Hossen", status: "P" },
    { id: 3, rollNumber: "3", name: "Ayush Das", parent: "Ranjit Das", status: "P" },
    { id: 4, rollNumber: "4", name: "Saniya Akter", parent: "Belal Miya", status: "P" },
    { id: 5, rollNumber: "5", name: "Mijannur Rahaman", parent: "Mahi Uddin Miah", status: "P" },
    { id: 6, rollNumber: "6", name: "Niladri Das", parent: "Nantu Das", status: "P" },
  ]);
  const [className, setClassName] = useState('');
  const [classId, setClassId] = useState('');
  const [sectionId, setSectionId] = useState('');
  const [sectionName, setSectionName] = useState('');

  useEffect(() => {
    if (props.match && props.match.params) {
      setClassName(props.match.params.class_name);
      setClassId(props.match.params.class_id);
      setSectionId(props.match.params.section_id);
      setSectionName(props.match.params.section_name);
    }
  }, [props.match]);

  const toggleStatus = (id) => {
    setStudents(prevStudents =>
      prevStudents.map(student =>
        student.id === id ? { ...student, status: student.status === 'P' ? 'A' : 'P' } : student
      )
    );
  };



  return (
    <Router>
      <Switch>
        <Route path={'/AdminMain/MonthAttendance/:class_id/:section_id/:class_name/:section_name'} exact render={() => {
          return (
            <div className="content">
              <div className="content-header">
                <div className="leftside-content-header">
                  <ul className="breadcrumbs">
                    <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Month Attendance</a></li>
                  </ul>
                </div>
              </div>
              <div className="bg_page_white_rounded box_shadow">
                <div className="student_Attendance-container">
                  <div className="student_Attendance-content">
                    <div className="student_Attendance-class-info">
                      <h2 className='student_Attendance-summary-item1'>Class: {className}</h2>
                      <div className="student_Attendance-date-switch">
                        <div className="student_Attendance-date-info">
                          <span>This Month</span>
                          <span>Date: 2024-09-03</span>
                        </div>
                        <label className="student_Attendance-switch">
                          <input type="checkbox" />
                          <span className="student_Attendance-slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div className="student_Attendance-student-list">
                      {students.map(student => (
                        <div
                          key={student.id}
                          className={`student_Attendance-student-card ${student.status === 'A' ? 'absent' : ''}`}
                          onClick={() => toggleStatus(student.id)}
                        >
                          <div className="student_Attendance-student-info">
                            <img src={boyImage} alt={student.name} />
                            <div className="student_Attendance-student-details">
                              <h3>{student.name}</h3>
                              <p>S/O: {student.parent}</p>
                            </div>
                          </div>
                          <div className="student_Attendance-student-status">
                            <div className="student_Attendance-roll-number">Roll No: {student.rollNumber}</div>
                            <span className={`student_Attendance-status-circle ${student.status === 'A' ? 'absent' : ''}`}>
                              {student.status}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="student_Attendance-attendance-summary">
                      <p className="student_Attendance-summary-item">Total Students: {students.length}</p>
                      <p className="student_Attendance-summary-item">Present: {students.filter(s => s.status === 'P').length}</p>
                      <p className="student_Attendance-summary-item">Leave: 0</p>
                      <p className="student_Attendance-summary-item">Absent: {students.filter(s => s.status === 'A').length}</p>
                    </div>
                    <button className="student_Attendance-submit-button">Submit Attendance</button>
                  </div>
                </div>
                {/* Class Info and Attendance Summary with View Buttons */}
                <div className="student_Attendance-container">
                  <div className="after_student_Attendance-header">
                    <h3>Class: 2</h3>
                    <h3>Date: 2024-08-01</h3>
                  </div>
                  <div className="after_student_Attendance-content">
                    <p>Student in Class: <span className="highlighted-data">0</span> <button className="student_Attendance-view-button">View</button></p>
                    <p>Showing Record Of: <span className="highlighted-data">11</span> <button className="student_Attendance-view-button">View</button></p>
                    <p>Admission After-2024-08-01: <span className="highlighted-data">-11</span> <button className="student_Attendance-view-button">View</button></p>
                  </div>

                  {/* New Student Attendance Summary Box */}
                  <div className="student_Attendance-summary-box">
                    <div className="student_Attendance_summary_item">
                      <p>Present: <span className="highlighted-data">20</span></p>

                      <Link to='/AdminMain/StudentsAttendance'>
                      <button className="student_Attendance-view-button">View</button>
                      </Link>

                    </div>
                    <div className="student_Attendance_summary_item">
                      <p>Absent: <span className="highlighted-data">5</span></p>
                      <button className="student_Attendance-view-button">View</button>
                    </div>
                    <div className="student_Attendance_summary_item">
                      <p>Leave: <span className="highlighted-data">2</span></p>
                      <button className="student_Attendance-view-button">View</button>
                    </div>
                  </div>
                </div>


              </div>

            </div>
          );
        }} />
      </Switch>
      <Route path="/AdminMain/StudentsAttendance"component={StudentsAttendance}/>
    </Router>
  );

};

export default MonthAttendance;

import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
export default class StudentsAttendance extends Component {
    constructor(props){
        super(props)
      const token=localStorage.getItem("token");
      let loggedIn=true;
      if(token==null){
        loggedIn=false;
      }
    
      this.state={
    
          loggedIn:true,
          Loading:false, 
          getClass:[],
          classBoard:[],
          classMedium:[],
          ClassSection:[]    
      }
    //  this.handleDropdownChange = this.handleDropdownChange.bind(this);
    }
    componentDidMount()
    {
    this.getClass();
    }
    getClass(){
    
    this.setState({
      Loading:true
    })
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    
    const FUNCTION_NAME='getClass.php'
    URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
    console.log("URL>>>>>"+URL)
    // var params = new URLSearchParams();
      //  params.append('school_code', SCHOOL_CODE);
    
    axios.get(URL,{
      params:{school_code:SCHOOL_CODE}
    }).then(res => res.data)
    .then((response) => {
     
      this.setState({ 
        Loading:false
       })
        console.log("Response2>>>>>"+response);
        let api_status=response.api_status;
       // alert(api_status);
        
        if(api_status=="OK")
        {
        
           this.setState({
            
            getClass:response.response.class,
            classBoard:response.response.board,
            classMedium:response.response.medium,
            ClassSection:response.response.class[0].section
            })
            console.log("response>>>>>"+this.state.getClass);
        }
        else{
          let api_remark=response.api_remark;
          myToast.notify_warning(api_remark);
        }
    
      //console.log("response>>>>>"+api_status)
     }).catch((error)=>{
      this.setState({ 
        Loading:false
       })
      console.log("error>>>>>"+error);
      });
    }
    handleDropdownChange(e){
        alert("hhh");
   // console.log("ggggggggggggggg>>>>>>>>>>");
    }

      render() {
        if( this.state.loggedIn==false)
        {
         return <Redirect to='/Login'/>;
        }
        if(this.state.Loading)
        {
           return <MyLoader/>
        }else{

    return (
      <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Students Attendance</a></li>
              </ul>
            </div>
          </div>   
          <div className="row white-bg-button">
                <div className="col-sm-3 col-md-3">
                <div className="b-bg-c-bg">
                <h4 className="button-circlep"> P </h4>
                </div>
                </div>
                <div className="col-sm-3 col-md-3">
                <div className="b-bg-c-bg">
                <h4 className="button-circlea"> A </h4>
                </div>
                </div>
                <div className="col-sm-3 col-md-3">
                <div className="b-bg-c-bg">
                <h4 className="button-circleli"> L </h4>
                </div>
                </div>
                <div className="col-sm-3 col-md-3">
                <div className="b-bg-c-bg">
                <h4 className="button-circleall"> All </h4>
                </div>
                </div>
                </div> 

              <div className="row class-li bg-student-prsent">
              <div className="col-sm-6 col-md-6">
                <Link to="#">
                <div className="row class-list-box">
                 <div className="col-sm-3 card-class-w">
                   <div  className="text-center">
                       <img src="https://treeminesglobal.com/wp-content/uploads/2019/01/placeholder-female-square.png" alt="student list image"/>
                       <small> S01234 </small>
                       </div>
                
                 </div>
                 <div className="col-sm-5 text-center">
                  <h5> Raj Kumar </h5>
                  <p> S/O: <span> Ramnath </span> </p>
                  <div className="row present-status">
                  <div className="col-sm-3">
                  <span className="prsent-status"> P </span>
                 </div>
                 <div className="col-sm-9">
                 <p>  Present </p>
                 </div>
                  </div>

                 </div>
                 <div className="col-sm-4 text-center">
                 
                 <p> Roll No. </p>
                 <p className="adt-roll"><span>12</span></p>
                  <h5>Class:<span> V </span>  </h5>
                 </div>
                </div>
                </Link>
                <div className="row student-attend-bottom">
                <div className="col-sm-4 col-md-4">
                 <button data-toggle="collapse" data-target="#demo-1"> Issue Notice </button>   
                </div>
                <div className="col-sm-4 col-md-4 bord-l-r">
                 <button data-toggle="collapse" data-target="#demo-2"> Send Message </button>   
                </div>
                <div className="col-sm-4 col-md-4">
                 <button data-toggle="collapse" data-target="#demo-3"> Make Call </button>   
                </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6">
                <Link to="#">
                <div className="row class-list-box">
                 <div className="col-sm-3 card-class-w">
                   <div  className="text-center">
                       <img src="https://treeminesglobal.com/wp-content/uploads/2019/01/placeholder-female-square.png" alt="student list image"/>
                       <small> S01234 </small>
                       </div>
                
                 </div>
                 <div className="col-sm-5 text-center">
                  <h5> Raj Kumar </h5>
                  <p> S/O: <span> Ramnath </span> </p>
                  <div className="row present-status">
                  <div className="col-sm-3">
                  <span className="prsent-status"> P </span>
                 </div>
                 <div className="col-sm-9">
                 <p>  Present </p>
                 </div>
                  </div>

                 </div>
                 <div className="col-sm-4 text-center">
                 
                 <p> Roll No. </p>
                 <p className="adt-roll"><span>12</span></p>
                  <h5>Class:<span> V </span>  </h5>
                 </div>
                </div>
                </Link>
                <div className="row student-attend-bottom">
                <div className="col-sm-4 col-md-4">
                 <button data-toggle="collapse" data-target="#demo-1"> Issue Notice </button>   
                </div>
                <div className="col-sm-4 col-md-4 bord-l-r">
                 <button data-toggle="collapse" data-target="#demo-2"> Send Message </button>   
                </div>
                <div className="col-sm-4 col-md-4">
                 <button data-toggle="collapse" data-target="#demo-3"> Make Call </button>   
                </div>
                  </div>
                </div>
                </div>
                {/* Add section on click issue,make call,send message  */}
                <section>
                <div className="panel-group">
     <div className="panel panel-default">
    <div id="demo-1" className="panel-collapse collapse">
      <div className="panel-body">
      <div className="row">
          <div className="col-sm-12 col-md-12">
              <div className="input-group"><h5>Add Notice:</h5><input type="text" id="Enter_notice" className="add-notice-text-p" value=""/></div>
              </div>
              <div className="col-sm-12 col-md-12">
              <div className="input-group"><h5>Ordered By:</h5><input type="text" id="Enter_ordered" className="notice-order" value="Clerk Department"/></div>
                  </div>
                  </div>
                  <div style={{ float: 'left', width: '100%',marginTop:'45px' }}>
                                <hr/>
                                <div className="form-horizontal form-bordered">
                                  <div className="form-group row">

                                    <div className="col-md-12">
                                      <center>
                                      <button className="btn btn-success" onClick="" style={{width:'25%',fontSize:'24px'}}> Add Notce </button>
                                       
                                      </center>
                                    </div>
                                  </div>
                                </div>
                              </div>
      </div>
      
    </div>
  </div>
</div>  
<div className="panel-group">
     <div className="panel panel-default">
    <div id="demo-2" className="panel-collapse collapse">
      <div className="panel-body">
      <div className="row">
          <div className="col-sm-12 col-md-12">
              <div className="input-group"><h5>Message Title:</h5><input type="text" id="Enter_notice" className="add-notice-text-p" placeholder="Enter Message Title" value=""/></div>
              </div>
              <div className="col-sm-12 col-md-12">
              <div className="input-group"><h5>Message:</h5><input type="text" id="Enter_ordered" className="notice-order" placeholder="Enter Message" value=""/></div>
                  </div>
            <div className="col-sm-12 col-md-12">
              <div className="input-group"><h5>Regards/Department:</h5><input type="text" id="Enter_ordered" className="notice-order" value="Clerk Department"/></div>
                  </div>
                  </div>
                  <div style={{ float: 'left', width: '100%',marginTop:'45px' }}>
                                <hr/>
                                <div className="form-horizontal form-bordered">
                                  <div className="form-group row">

                                    <div className="col-md-12">
                                      <center>
                                      <button className="btn btn-success" onClick="" style={{width:'25%',fontSize:'24px'}}> Send Message </button>
                                       
                                      </center>
                                    </div>
                                  </div>
                                </div>
                              </div>
      </div>
      
    </div>
  </div>
</div>  
<div className="panel-group">
     <div className="panel panel-default">
    <div id="demo-3" className="panel-collapse collapse">
      <div className="panel-body">
      <div className="row">
          <div className="col-sm-4 col-md-4">
              <div className="input-group">
              <h5> 98765432312 </h5>
              </div>
              </div>
              <div className="col-sm-4 col-md-4">
              <div className="input-group">
                  <h5> Rakesh(<span> Father </span>) </h5>
              </div>
            </div>
            <div className="col-sm-4 col-md-4">
              <div className="input-group">
                  <h5><button> Make Call </button>  </h5>
              </div>
                  </div>
                  </div>
      </div>
      
    </div>
  </div>
</div>  
                </section>
               </div>

  )
} 
}
}

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import COLORS from "../resources/colors/COLORS";
import Attendance from "./Attendance";
import AttendancePieChart from "../ChildComponent/AttendancePieChart ";
import TodayAttendance from "./TodayAttendance";

const AttendanceDashboard = () => {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("token") !== null
  );
  const [loading, setLoading] = useState(false);
  const [sessionID, setSessionID] = useState("");
  const [classID, setClassID] = useState("");
  const [sectionID, setSectionID] = useState("");
  const [classAttendanceStatusData, setClassAttendanceStatusData] = useState(
    []
  );
  const [mFilteredClassSectionList, setFilteredClassSectionList] = useState([]);
  const [classBoard, setClassBoard] = useState([]);
  const [classMedium, setClassMedium] = useState([]);
  const [mClassList, setClassList] = useState([]);
  const [selectedClassPosition, setSelectedClassPosition] = useState("");

  const sltBoard = useRef();
  const sltMedium = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    getClassAttendanceStatus();
  }, []);

  useEffect(() => {
    if (mClassList.length > 0) {
      getClassWithAttendanceStatus();
    }
  }, [mClassList]);

  const onBoardChange = () => {
    setSelectedClassPosition(0);
    getClassWithAttendanceStatus();
  };

  const onMediumChange = () => {
    setSelectedClassPosition(0);
    getClassWithAttendanceStatus();
  };

  const getClassAttendanceStatus = () => {
    setClassAttendanceStatusData([]);
    setLoading(true);

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const session = SessionManager.getCurrentSession();
    const SESSION_ID = session[0].session_id;
    const DAYS = "1";

    const FUNCTION_NAME = "getClassAttendenceStatus2.php?";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;
    const params = {
      school_code: SCHOOL_CODE,
      session_id: SESSION_ID,
      days: DAYS,
    };

    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);

    axios
      .get(URL, {
        params: params,
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        if (response.api_status === "OK") {
          setClassBoard(response.data.board);
          setClassMedium(response.data.medium);
          setClassAttendanceStatusData(response.data.ClassAttendenceStatus);
          setClassList(response.data.class_list);

          //getClassWithAttendanceStatus();
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const getClassWithAttendanceStatus = () => {
    const boardID =
      sltBoard.current.selectedOptions[0].getAttribute("board_id");
    const mediumID =
      sltMedium.current.selectedOptions[0].getAttribute("medium_id");

    // alert(JSON.stringify(mClassList));

    const filteredClassList = mClassList.filter(
      (item) => item.board_id === boardID && item.medium_id === mediumID
    );
    //alert(JSON.stringify(filteredClassList));
    const filteredClassSections = [];

    filteredClassList.forEach((item) => {
      if (item.section === "No") {
        filteredClassSections.push({
          ...item,
          section_id: "No",
          section_name: "",
        });
      } else {
        item.section.forEach((section) => {
          filteredClassSections.push({
            ...section,
            board_id: boardID,
            board_name: item.board_name,
            medium_id: mediumID,
            medium_name: item.medium_name,
          });
        });
      }
    });
    //alert(JSON.stringify(filteredClassSections));
    setFilteredClassSectionList(filteredClassSections);
  };

  const getClassAttendance = (item) => {
    var data_item = null;
    for (let index = 0; index < classAttendanceStatusData.length; index++) {
      const element = classAttendanceStatusData[index];
      if (
        element.class_id === item.class_id &&
        element.section_id === item.section_id
      ) {
        data_item = element;
        return data_item;
      }
    }
    return data_item;
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  return (
    <Router>
      <Route
        path={"/AdminMain/AttendanceDashboard/:module_id"}
        exact
        render={() => (
          <div className="content" style={{ background: COLORS.white }}>
            <div className="content-header">
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li>
                    <i className="fa fa-home" aria-hidden="true" />
                    <a href="#">Attendance Dashboard </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="bg_page_grey_rounded box_shadow"
              style={{ "--mMargin": "50px" }}
            >
              {loading && <MyLoader />}
              <div
                className="bg_card_blue"
                style={{ padding: "5px 5px 10px 5px" }}
              >
                <div className="row m-0">
                  <div className="col-sm-6 col-md-6">
                    <div className="">
                      <h5>Select Boards:</h5>
                      <select
                        className="select-field"
                        onChange={onBoardChange}
                        ref={sltBoard}
                      >
                        {classBoard &&
                          classBoard.map((item, index) => (
                            <option board_id={item.board_id}>
                              {item.board_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <div className="">
                      <h5>Select Medium</h5>
                      <select
                        className="select-field"
                        onChange={onMediumChange}
                        ref={sltMedium}
                      >
                        {classMedium &&
                          classMedium.map((item, index) => (
                            <option medium_id={item.medium_id}>
                              {item.medium_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <section>
                <h5>Today:Attendance Status(Quick View)</h5>
                <div
                  className="bg_card_blue_outline"
                  style={{ padding: "5px" }}
                >
                  <div
                    className="row "
                    style={{ padding: "0px 15px 0px 15px" }}
                  >
                    {mFilteredClassSectionList &&
                      mFilteredClassSectionList.map((item, index) => {
                        // alert(mAttendance);
                        var attendance = getClassAttendance(item);
                        if (attendance != null) {
                          var attendance_status = attendance.status;
                          // var attendance_status="Taken";
                          return (
                            <div
                              className="col-sm-2 col-md-2"
                              style={{ padding: "0px 2px 5px 2px" }}
                            >
                              <Link
                                to={
                                  item.section === "No"
                                    ? "/AdminMain/TodayAttendance/" +
                                      item.class_id +
                                      "/" +
                                      "No/" +
                                      item.class_name +
                                      "/" +
                                      null
                                    : "/AdminMain/TodayAttendance/" +
                                      item.class_id +
                                      "/" +
                                      item.section_id +
                                      "/" +
                                      item.class_name +
                                      "/" +
                                      item.section_name
                                }
                              >
                                <div
                                  className={
                                    attendance_status === "Taken"
                                      ? "bg_green_outline_green gravity_center"
                                      : "bg_white_outline_grey gravity_center"
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <p style={{ fontSize: 14 }}>
                                    {" "}
                                    {item.section === "No"
                                      ? "Class: " + item.class_name
                                      : item.class_name +
                                        ":" +
                                        item.section_name}{" "}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          );
                        } else {
                          var attendance_status = "Not Taken";
                          return (
                            <div
                              className="col-sm-2 col-md-2"
                              style={{ padding: "0px 2px 5px 2px" }}
                            >
                              <Link
                                to={
                                  item.section === "No"
                                    ? "/AdminMain/TodayAttendance/" +
                                      item.class_id +
                                      "/" +
                                      "No/" +
                                      item.class_name +
                                      "/" +
                                      null
                                    : "/AdminMain/TodayAttendance/" +
                                      item.class_id +
                                      "/" +
                                      item.section_id +
                                      "/" +
                                      item.class_name +
                                      "/" +
                                      item.section_name
                                }
                              >
                                <div
                                  className={
                                    attendance_status === "Taken"
                                      ? "bg_green_outline_green gravity_center"
                                      : "bg_white_outline_grey gravity_center"
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <p style={{ fontSize: 14 }}>
                                    {" "}
                                    {item.section === "No"
                                      ? "Class: " + item.class_name
                                      : item.class_name +
                                        ":" +
                                        item.section_name}{" "}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
              </section>

              <section>
                <hr />
                <h5>Today:Attendance Status</h5>
                <div className="row">
                  {mFilteredClassSectionList &&
                    mFilteredClassSectionList.map((item, index) => {
                      var attendence = getClassAttendance(item);
                      if (attendence != null) {
                        var attendance_status = attendence.status;
                        var present;
                        var absent;
                        var leave;
                        var btn_lable = "Take Attendance";
                        if (attendance_status == "Taken") {
                          btn_lable = "View Attendance";
                          present = attendence.P;
                          absent = attendence.A;
                          leave = attendence.L;
                          if (present == null) present = "0";
                          if (absent == null) absent = "0";
                          if (leave == null) leave = "0";
                        } else {
                          btn_lable = "Take Attendance";
                          present = "N/A";
                          absent = "N/A";
                          leave = "N/A";
                        }
                      } else {
                        btn_lable = "Take Attendance";
                        present = "N/A";
                        absent = "N/A";
                        leave = "N/A";
                      }

                      return (
                        <>
                          {attendance_status === "Taken" ? (
                            <div className="col-sm-4 col-md-4">
                              <div
                                className={
                                  attendance_status === "Taken"
                                    ? "bg_card_green_outline  box_shadow_primary"
                                    : "bg_card_blue_outline  box_shadow_primary"
                                }
                                style={{
                                  margin: "5px 2px 10px 3px",
                                  height: "200px",
                                }}
                              >
                                <div className="gravity_center">
                                  <p style={{ fontSize: "18px" }}>
                                    <strong>
                                      {item.section === "No"
                                        ? "Class: " + item.class_name
                                        : item.class_name +
                                          ":" +
                                          item.section_name}{" "}
                                    </strong>
                                  </p>
                                </div>

                                <AttendancePieChart
                                  Present={present}
                                  Absent={absent}
                                  Leave={leave}
                                ></AttendancePieChart>

                                <hr />

                                <div
                                  className="row"
                                  style={{ padding: "0px 5px 0px 5px" }}
                                >
                                  <div className="col-sm-12 col-md-12 text-center">
                                    <button
                                      className="btn btn-info"
                                      style={{
                                        width: "100%",
                                        height: "30px",
                                        fontSize: "16px",
                                        margin: "5px 5px 5px 0px",
                                        padding: "0px",
                                      }}
                                    >
                                      {" "}
                                      <i class="fa fa-edit"></i> &nbsp;{" "}
                                      {btn_lable}{" "}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-4 col-md-4">
                              <div
                                className={
                                  attendance_status === "Taken"
                                    ? "bg_card_green_outline  box_shadow_primary"
                                    : "bg_card_blue_outline  box_shadow_primary"
                                }
                                style={{
                                  margin: "5px 2px 10px 3px",
                                  height: "200px",
                                }}
                              >
                                <div className="gravity_center">
                                  <p style={{ fontSize: "18px" }}>
                                    <strong>
                                      {item.section === "No"
                                        ? "Class: " + item.class_name
                                        : item.class_name +
                                          ":" +
                                          item.section_name}{" "}
                                    </strong>
                                  </p>
                                </div>

                                <AttendancePieChart
                                  Present={present}
                                  Absent={absent}
                                  Leave={leave}
                                ></AttendancePieChart>

                                <hr />

                                <div
                                  className="row"
                                  style={{ padding: "0px 5px 0px 5px" }}
                                >
                                  <div className="col-sm-12 col-md-12 text-center">
                                    <button
                                      className="btn btn-info"
                                      style={{
                                        width: "100%",
                                        height: "30px",
                                        fontSize: "16px",
                                        margin: "5px 5px 5px 0px",
                                        padding: "0px",
                                      }}
                                    >
                                      {" "}
                                      <i class="fa fa-edit"></i> &nbsp;{" "}
                                      {btn_lable}{" "}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                </div>
              </section>
            </div>
          </div>
        )}
      />
      <Route
        path="/AdminMain/TodayAttendance/:class_id/:section_id/:class_name/:section_name"
        component={TodayAttendance}
      />
    </Router>
  );
};

export default AttendanceDashboard;

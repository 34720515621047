import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom'
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
import Table from 'react-bootstrap/Table'

import ExamStudentReportCard from './ExamStudentReportCard';
import MdlViewStudentAttendance from '../dialog/MdlViewStudentAttendance';
import MdlEditStudentAttendance from '../dialog/MdlEditStudentAttendance';
import MdlEditStudentRemark from '../dialog/MdlEditStudentRemark';
export default class ExamReportCardRankList extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
    
        loggedIn:true,
        Loading:false, 
        LoadingStudent:false,
        LoadingRankList:false,
        isOpenEditStudentRemark:false,
        mExamMarkingSystem:[],
        mExamParticipatingClass:[],
        mStudentList:[],
        mClassRank:[],
        mStudentRank:[],
    
        EXAM_ID:'',
        STUDENT_ID:'',
        REPORT_CARD_ID:'',
        SESSION_ID:'',
        CLASS_ID:'',
        SECTION_ID:'',
        CLASS_SELECTED_POSITION:-1,
        SELECTED_STUDENT:''
        
       

    }
   
    this.HandleCloseEditStudentRemark = this.HandleCloseEditStudentRemark.bind(this);
    
  
  }


  EditStudentAttendance=(item2)=>{
    //alert(JSON.stringify(item2));
         
    this.setState({
    
      isOpenEditStudentAttendance:true,
      SELECTED_STUDENT:item2
    
    })
   
  }
  
  HandleCloseEditStudentAttendance = () => {

    this.setState({
      isOpenEditStudentAttendance:false,

    }); 
    this.getClassStudentAttendanceReport();

     
  }
  EditStudentRemark=(item2)=>{
    //alert(JSON.stringify(item2));
         
    this.setState({
    
      isOpenEditStudentRemark:true,
      SELECTED_STUDENT:item2
    
    })
   
  }
  HandleCloseEditStudentRemark = () => {

    this.setState({
      isOpenEditStudentRemark:false,

    });  
    this.getClassStudentRankList();
     
  }
  componentDidMount()
  {
  
    this.state.REPORT_CARD_ID=this.props.match.params.report_card_id;
    this.state.SESSION_ID=this.props.match.params.session_id;   
  
    window.scrollTo(0, 0); 
    this.getReportCardParticipatingClass();  
  }

  getReportCardParticipatingClass(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  const FUNCTION_NAME='getReportCardParticipatingClass.php?'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME
  console.log("URL>>>>>"+URL)
  // var params = new URLSearchParams();
    //  params.append('school_code', SCHOOL_CODE);
    const params={
      school_code:SCHOOL_CODE,
      session_id:this.state.SESSION_ID,
      report_card_id:this.state.REPORT_CARD_ID,
    
    }
    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);
  
  
  axios.get(URL,{
    params:params
  }).then(res => res.data)
  .then((response) => {
   
    this.setState({ 
      Loading:false
     })
       let api_status=response.api_status;
       //alert(JSON.stringify(response));
      
      if(api_status=="OK")
      {
      
         this.setState({         
             mExamParticipatingClass:response.data,
                   
          })
          if(this.state.mExamParticipatingClass.length!==0)
          {
            this.state.EXAM_ID=this.state.mExamParticipatingClass[0]['exam_id'];

          }
          this.setClassList(this.state.CLASS_SELECTED_POSITION);
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
 
  selectClass=(position)=>{  
    this.setClassList(position);
    
  }
  setClassList(selected_position){ 
    if(selected_position>=0)
    {
      if(this.state.mExamParticipatingClass.length!==0)
      {
          this.state.CLASS_ID=this.state.mExamParticipatingClass[selected_position].class_id;
          this.state.SECTION_ID=this.state.mExamParticipatingClass[selected_position].section_id;
  
          if(this.state.SECTION_ID=="null")
          {
              this.state.SECTION_ID="No";
          }
          this.state.CLASS_SELECTED_POSITION=selected_position;
  
      }
  
      this.getClassStudentRankList();
    }     
   
   
  }



    getClassStudentRankList(){
      this.state.mClassRank=[];
      this.state.mStudentList=[];
      this.setState({
        LoadingRankList:true
      })
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      const FUNCTION_NAME='getReportCardClassRankAndStudent.php?';
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
      console.log("URL>>>>>"+URL);

      const params={
        school_code:SCHOOL_CODE,
        session_id:this.state.SESSION_ID,
        class_id:this.state.CLASS_ID,
        section_id:this.state.SECTION_ID,
        report_card_id:this.state.REPORT_CARD_ID,
      
      }

      const queryString = new URLSearchParams(params).toString();
      console.log("Full URL: ", `${URL}${queryString}`);
  
    
      axios.get(URL,{
        params:params
       
        
      }).then(res => res.data)
      .then((response) => {
       
       
        this.setState({ 
          LoadingRankList:false
         })
          let api_status=response.api_status;
          //alert(JSON.stringify(response));
          
          if(api_status=="OK")
          {
          
             this.setState({         
                 mStudentList:response.data.student_list,
                 mClassRank:response.data.rank_list,
                       
              })
           this.mergeData();
            
          }
          else{
            let api_remark=response.api_remark;
            //myToast.notify_warning(api_remark);
          }
      
        //console.log("response>>>>>"+api_status)
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        console.log("error>>>>>"+error);
        });
      }
    mergeData()
    {
          this.state.mStudentRank=[];
          for(let i=0; i<this.state.mStudentList.length; i++) {
          this.state.mStudentRank.push({
            ...this.state.mStudentList[i], 
              ...(this.findRank(this.state.mStudentList[i].student_enrollment_no))}
          );
        }
        //this.state.mStudentRank.sort((a,b) => (a.rank > b.rank) ? 1 : ((b.rank > a.rank) ? -1 : 0))
        this.state.mStudentRank.sort((a,b) => a.rank - b.rank);
        //this.state.mStudentRank.sort((a, b) => (a.rank > b.rank) ? 1 : -1);
       
        this.setState({

        })

      console.log(this.state.mStudentRank);
    }
    findRank(student_id)
    {
          var FOUND=0;
          var element=[];
          for (let index = 0; index < this.state.mClassRank.length; index++) {
             element = this.state.mClassRank[index];
            if(element.student_id===student_id)
            {
              FOUND=1;
              break;
              
            }
            
          }
          if(FOUND==1)
          {
            return element;
          }
          else{
            var item=[];
            item['rank']="0";
            item['obtained_mark']="AA";
            item['max_mark']="";
            return item;
          }
    }
      getPercetage(obt_mark,total_max)
      {
            var per=(obt_mark*100)/total_max;
            per=per.toFixed(2)+" "+"%";
            return per
      }
  
    render() {
      if( this.state.loggedIn==false)
      {
       return <Redirect to='/Login'/>;
      }
      if(this.state.Loading)
      {
         return <MyLoader/>
      }else{ 
        
        return (             
        <Router>
        <switch>
          <Route path={'/AdminMain/ExamReportCardRankList/:report_card_id/:session_id'}  exact render={
            ()=>{
              
         return (
       
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Rank List</a></li>
              </ul>
            </div>
        
          </div>    
        
          <div className="animated fadeInUpn bg_page_white_rounded box_shadow" style={{'--mMargin':'50px'}}>
      
        
        
         
          <div className="row gravity_center" style={{margin:'2px 15px 2px 15px'}}>
               
               <div className="col-sm-6 col-md-6 grid_primery_outline_primery" style={{height:'40px'}}>
                 <p  style={{padding:'2px',fontSize:'18px'}}><strong> ReportCard Caption:</strong> {this.state.mExamMarkingSystem.length!==0? this.state.mExamMarkingSystem[0]['report_card_name']:''} </p> 
               </div>
               <div className="col-sm-3 col-md-3 bg_grid_primary_2" style={{height:'40px'}}>
                  <div className='gravity_center'>              
                    <Link to={"/AdminMain/ExamStudentReportCard/"}>
                        
                     </Link>
                    </div>

               </div>
               <div className="col-sm-3 col-md-3 bg_grid_primary_2 " style={{height:'40px'}}>
                 <div className='gravity_center'>              
                    <Link to={"/AdminMain/ExamStudentReportCard/"+this.state.REPORT_CARD_ID+"/"+this.state.EXAM_ID+"/"+this.state.SESSION_ID+"/"+"null"}>
                    <button className="bg_card_primary btn btn-success">Get Report Card</button>
                     </Link>
                    </div>

               </div>
                
         </div>
          <div style={{padding:'0px 15px 0px 15px'}}><h5>Select Participating Class:</h5></div>
                    <div className="row bg_card_primary" style={{padding:'5px',margin:'0px 15px 0px 15px'}}>
                    {this.state.mExamParticipatingClass.map((item, index) => (
                    <div className="col-sm-1 col-md-1" style={{padding:'0px 2px 5px 2px'}}>
                        <div className={this.state.CLASS_SELECTED_POSITION===index?"bg_class_select gravity_center":"bg_class_deselect gravity_center"} onClick={e=>this.selectClass(index)} style={{cursor:'pointer'}}> 
                           <p style={{fontSize:14}}> {item.section_id=='No'?item.class_name:item.class_name+':'+item.section_name} </p>
                        </div>
                    </div>
                    ))}
         </div>
       

        

              <div style={{padding:'0px 15px 0px 15px'}}><h5>Student Rank in Class And  Remark:</h5></div>
                {this.state.LoadingRankList?<div><MyLoader/></div>:
                <div>
                <Table striped bordered hover  style={{width:'100%',border:'1'}}>
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center" }}>Sr.</th>
                              <th style={{ textAlign: "center" }}>Student Name</th>
                              <th style={{ textAlign: "center" }}>Father name</th>
                              <th style={{ textAlign: "center" }}>Class</th>
                              <th style={{ textAlign: "center" }}>Roll No</th>
                             
                              <th style={{ textAlign: "center" }}>Obtain Mark</th>
                              <th style={{ textAlign: "center" }}>Percentage</th>
                              <th style={{ textAlign: "center" }}>Rank</th>
                              <th style={{ textAlign: "center" }}>Remark</th>
                              <th style={{ textAlign: "center",width:'150px' }}>Action</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.mStudentRank && this.state.mStudentRank.map((item2,index2)=>{
                          // console.log("student"+JSON.stringify(item2));
                          return(   
                            <tr>
                              <td style={{ textAlign: "center" }}>{index2+1}</td>
                              <td style={{ textAlign: "center" }}>{item2.stu_name}</td>
                              <td style={{ textAlign: "center" }}>{item2.father_name}</td>
                              <td style={{ textAlign: "center" }}>{item2.section_id==='No'?item2.class_name:item2.class_name+":"+item2.section_name}</td>
                              <td style={{ textAlign: "center" }}>{item2.student_roll_no}</td>
                             
                              <td style={{ textAlign: "center" }}>{item2.obtained_mark+'/'+item2.max_mark}</td>
                              <td style={{ textAlign: "center" }}>{this.getPercetage(item2.obtained_mark,item2.max_mark)}</td>
                              <td style={{ textAlign: "center" }}>{item2.rank}</td>
                              <td style={{ textAlign: "center" }}>{item2.remark}</td>

                              <td className='gravity_center_row'> 
                                   <button className="bg_card_primary btn btn-success gravity_center" style={{height:'24px'}} onClick={()=>this.EditStudentRemark(item2)}>Edit Remark</button>
                                   <div className='gravity_center'>              
                                     <Link to={"/AdminMain/ExamStudentReportCard/"+this.state.REPORT_CARD_ID+"/"+this.state.EXAM_ID+"/"+this.state.SESSION_ID+"/"+item2.student_enrollment_no}>
                                           <button className="bg_card_primary btn btn-success gravity_center" style={{height:'24px'}}>Report Card</button>
                                       </Link>
                                  </div>
                              </td>
                              
                            </tr>
                          )
                        })}

                          </tbody>
                        </Table>
                        </div>

                }       
                          
               

               
            </div>
           
                     {this.state.isOpenEditStudentRemark===true? <MdlEditStudentRemark selected_student={this.state.SELECTED_STUDENT} report_card_id={this.state.REPORT_CARD_ID} action={this.HandleCloseEditStudentRemark}></MdlEditStudentRemark>:''}

              
         
     </div>


           
           )
          }
          } />
             <Route path='/AdminMain/ExamStudentReportCard/:report_card_id/:exam_id/:session_id/:student_id'  component={ExamStudentReportCard} />
            
                 
    </switch>       
   </Router>  
    )
  }
 }
 }
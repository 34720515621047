import React, { Component } from "react";
import { css } from "@emotion/core";
import HashLoader from "react-spinners/HashLoader";

const override = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* Ensures full width */
  height: 100%; /* Ensures full height */
  background: rgba(255, 255, 255, 0.9); /* Semi-transparent white */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Places the loader on top of other elements */
  overflow: hidden; /* Prevents scrolling */
  transform: none; /* Prevents unintended rotation or skewing */
  pointer-events: all; /* Blocks UI interactions underneath */
`;

export default class MyLoader extends Component {
  render() {
    return (
      <div>
        <div className="sweet-loading">
          <HashLoader
            css={override}
            sizeUnit={"px"}
            size={100}
            color={"#123abc"}
          />
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import COLORS from "../resources/colors/COLORS";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import Iframe from "react-iframe";
import SelectClass from "../ChildComponent/SelectClass";
import SelectAllClass from "../ChildComponent/SelectAllClass";
export default class StudentAttendanceReport extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      showPreview: false,
      iFrameHeight: "0px",
      FILE_URL: "",
      FILE_NAME: "",
      FILE_DIRECTORY: "",
      DOCUMENT_TYPE: "",
      SESSION_ID: "",
      CLASS_ID: "",
      SECTION_ID: "",
      SELECTED_CLASS_POSITION: -1,
      mPrintableSamples: [],
      SELECTED_SAMPLE_ID: "",
      SELECTED_SAMPLE_POSITION: 0,
      CLASS_SECTION_NAME: "",
      mColorOption: [],
      mYearMonth: [],
      mOptions: [],
      selectedOption: "",
    };

    this.CallBackClassSelect = this.CallBackClassSelect.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.state.DOCUMENT_TYPE = this.props.match.params.document_type;
    this.setState({
      selectedOption: this.getCurrentYearMonth(),
    });

    const mSession = SessionManager.getCurrentSession();
    const start_date = new Date(mSession[0].start_date);
    const end_date = new Date(mSession[0].end_date);

    const mYearMonth = [];
    while (start_date <= end_date) {
      const year = start_date.getFullYear();
      const month = String(start_date.getMonth() + 1).padStart(2, "0");
      mYearMonth.push(`${year}-${month}`);
      start_date.setMonth(start_date.getMonth() + 1);
    }
    this.setState({ mOptions: mYearMonth });
  }

  getCurrentYearMonth = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Add leading zero to month
    return `${year}-${month}`;
  };
  CallBackClassSelect = (
    class_id,
    section_id,
    session_id,
    selected_position,
    class_name,
    section_name,
    session_name,
    start_date,
    end_date
  ) => {
    this.state.CLASS_ID = class_id;
    this.state.SECTION_ID = section_id;
    this.state.SESSION_ID = session_id;
    this.state.SELECTED_CLASS_POSITION = selected_position;
    //alert("Back"+class_id+"/"+section_id+"/"+session_id);
    if (section_id == "No") {
      this.state.CLASS_SECTION_NAME = class_name;
    } else {
      this.state.CLASS_SECTION_NAME = class_name + ":" + section_name;
    }

    if (this.state.mPrintableSamples.length == 0) {
      this.getPrintableSamples();
    } else {
      this.setFileUrL();
    }
  };

  getPrintableSamples() {
    this.setState({
      Loading: true,
    });

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getPrintableSample.php?";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    // alert(URL);
    //console.log("URL>>>>>"+URL);

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          document_type: this.state.DOCUMENT_TYPE,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          Loading: false,
        });
        //console.log("Response2>>>>>"+response);
        let api_status = response.api_status;
        //alert(JSON.stringify(response));

        if (api_status == "OK") {
          this.setState({
            mPrintableSamples: response.data,
          });
          //alert("mPrintableSamples>>>>>"+JSON.stringify(this.state.mPrintableSamples));
          this.setSample();
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        // console.log("error>>>>>"+error);
      });
  }
  setSample() {
    var sample_id = this.state.mPrintableSamples[0].sample_id;
    this.state.FILE_NAME = this.state.mPrintableSamples[0].file_name;
    this.state.FILE_DIRECTORY = this.state.mPrintableSamples[0].file_directory;
    this.state.SELECTED_SAMPLE_POSITION = 0;
    this.state.SELECTED_SAMPLE_ID = sample_id;
    this.setFileUrL();
  }

  setFileUrL() {
    const SCHOOL_CODE = SessionManager.getSchoolCode();

    this.state.FILE_URL =
      process.env.REACT_APP_SERVER_NAME +
      this.state.FILE_DIRECTORY +
      this.state.FILE_NAME +
      "?&class_id=" +
      this.state.CLASS_ID +
      "&section_id=" +
      this.state.SECTION_ID +
      "&class_section_name=" +
      this.state.CLASS_SECTION_NAME +
      "&session_id=" +
      this.state.SESSION_ID +
      "&month=" +
      this.state.selectedOption +
      "&sample_id=" +
      this.state.SELECTED_SAMPLE_ID +
      "&system_code=" +
      process.env.REACT_APP_SYSTEM_CODE +
      "&school_code=" +
      SCHOOL_CODE;
    console.log("FILE_URL", this.state.FILE_URL);
    //alert(this.state.FILE_URL);
    this.setState({
      showPreview: true,
    });
  }

  handleChange = (event) => {
    const value = event.target.value;
    this.setState(
      {
        selectedOption: value,
      },
      () => {
        this.setFileUrL();
      }
    );
  };
  autoResize = () => {
    //alert("loaded");
    var iFrame = document.getElementById("iFrame1");

    iFrame.width = iFrame.contentWindow.document.body.scrollWidth;
    iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
  };

  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to="/Login" />;
    }
    if (this.state.Loading) {
      return <MyLoader />;
    } else {
      return (
        <div className="content">
          <div
            className="content-header"
            style={{ background: COLORS.primary_color_shade5 }}
          >
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li>
                  <i
                    className="fa fa-home"
                    aria-hidden="true"
                    style={{ color: COLORS.white }}
                  />
                  <a href="#">Udise S02 Form </a>
                </li>
              </ul>
            </div>
          </div>

          <div
            className="animated fadeInUpn bg_page_grey_rounded box_shadow"
            style={{ "--mMargin": "50px" }}
          >
            <SelectClass
              action={this.CallBackClassSelect}
              selected_position={this.state.SELECTED_CLASS_POSITION}
            ></SelectClass>

            <div className="row">
              <div className="col-sm-12 col-lg-12 padding_0 ">
                <div
                  className="bg_card_primary_outline"
                  style={{
                    padding: "5px",
                    margin: "0px 5px 0px 0px",
                  }}
                >
                  <div className="dropdown-container-flex">
                    <h3>Select Month</h3>
                    <select
                      className="styled-select"
                      onChange={this.handleChange}
                      value={this.state.selectedOption}
                    >
                      {this.state.mOptions.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ margin: "0px 10px 0px 10px" }}>
              <h5 className="">Preview</h5>
            </div>

            <div className="row" style={{ margin: "0px 10px 0px 10px" }}>
              {this.state.showPreview ? (
                <div>
                  <MyLoader />
                </div>
              ) : (
                ""
              )}
              <Iframe
                url={this.state.FILE_URL}
                width={this.state.showPreview ? "0%" : "100%"}
                height={this.state.showPreview ? "0px" : "800px"}
                overflow="auto"
                id="iFrame1"
                className=""
                display="initial"
                position="relative"
                onLoad={() => {
                  //this.autoResize();

                  this.setState({
                    showPreview: false,
                  });
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  }
}
